import React from "react"
import styled from "styled-components"

import Footer from "../components/Footer"
import TopBar from "../components/TopBar"
import Layout from "../containers/Layout"
import Sponsors from "../components/Sponsors"
import Welcome from "../components/Welcome"

import { BorderRadius, Colors, Spacing, Shadows } from "../rules"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const App = () => {
  return (
    <Layout>
      <TopBar />
      <Main>
        <Section border={"none"} borderWidth={"0"}>
          <Welcome />
        </Section>
        <Section margin={Spacing.lg} marginColumn={"0 " + Spacing.lg}>
          <Map>
            <iframe
              title={"Fenix_map"}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.4351589412104!2d-75.5823931!3d6.2061893!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4682791ed25a5d%3A0x66716bb0048bf8d!2sCancha%20Cristo%20Rey!5e0!3m2!1sen!2sco!4v1584904098134!5m2!1sen!2sco"
              allowFullScreen=""
              aria-hidden="false"
              frameBorder="0"
              tabIndex="0"
            />
          </Map>
          <Contactos>
            <Preguntas>
              <h2>Entrenamientos</h2>
              <h4>Cancha Cristo Rey</h4>
              <p>
                <a href={"https://goo.gl/maps/N27fa8REqEqF1vmu7"}>
                  a 2 99, Cl. 2 Sur, Medellín, Antioquia
                </a>
              </p>
              <p>
                Martes y Jueves.
                <br />
                <FontAwesomeIcon icon={["fas", "dumbbell"]} /> Pesos de las 8 a
                las 9 pm.
                <br />
                <FontAwesomeIcon icon={["fas", "users"]} /> Entrenamiento
                colectivo de las 9 a las 11 pm.
              </p>
            </Preguntas>
            <Preguntas>
              <h2>Partidos</h2>
              <h4>Unidad Deportiva Castilla</h4>
              <p>
                <a href={"https://goo.gl/maps/NviRjzWRJgWqoQwV9"}>
                  Cra. 69 #N°101 - 25, Medellín, Antioquia
                </a>
              </p>
              <p>Sabados o Domingos, en la tarde.</p>
            </Preguntas>
            <Preguntas>
              <ButtonInSection
                href={
                  "https://wa.me/573183472689?text=Bienvenidos%20a%20la%20%23FamiliaFRC%2C%20para%20mas%20informacion%20sobre%20entrenamientos%20y%20partidos%2C%20contactanos%20en%20breve%20te%20responderemos."
                }
              >
                Ven y juega rugby con la familia Fénix
              </ButtonInSection>
            </Preguntas>
          </Contactos>
        </Section>
        <Section
          border={"solid" + Colors.primary.blue.default}
          borderWidth={"2px 0 0 0"}
        >
          <Sponsors />
        </Section>
      </Main>
      <Footer />
    </Layout>
  )
}

const Main = styled("div")`
  flex: 1 0 auto;
  justify-content: center;
  color: ${Colors.utility.paragraph.default};
`

const Section = styled("div")`
  display: flex;
  flex-direction: row;
  border: ${props => props.border && props.border};
  border-width: ${props => props.borderWidth && props.borderWidth};
  margin: ${props => props.margin && props.margin};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin: ${props => props.marginColumn && props.marginColumn};
  }
`

const ButtonInSection = styled("a")`
  background-color: ${Colors.primary.blue.darker};
  border-radius: ${BorderRadius.md};
  box-shadow: ${Shadows.level2};
  padding: ${Spacing.md} ${Spacing.lg};
  text-align: center;
  text-decoration: none;
  color: white !important;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    transform: translateY(-1px);
    box-shadow: ${Shadows.level4};
    background-color: ${Colors.primary.blue.default};
  }
`

const Map = styled("div")`
  border: 2px solid ${Colors.primary.blue.default};
  flex: 1 1 auto;
  min-width: 300px;
  iframe {
    border: 0;
    width: 100%;
    height: 550px;
  }
  margin-right: ${Spacing.md};
  padding: 3px;
  @media screen and (max-width: 900px) {
    margin: ${Spacing.md};
    width: 99%;
  }
`

const Contactos = styled("div")`
  border: 2px solid ${Colors.primary.blue.default};
  display: flex;
  flex-direction: column;
  margin-left: ${Spacing.md};
  padding: 3px;
  @media screen and (max-width: 900px) {
    margin: ${Spacing.md};
    width: 99%;
    height: auto;
  }
  height: 552px;
`

const Preguntas = styled("div")`
  margin: ${Spacing.sm} ${Spacing.xl};
  a {
    color: ${Colors.primary.blue.default};
  }
`

export default App
