import React from "react"
import styled from "styled-components"
import { Colors, Shadows, Spacing, Typography } from "../rules"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Welcome = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "team/team-4.jpeg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const backgroundFluidImageStack = [
    data.file.childImageSharp.fluid,
    `linear-gradient(rgba(35, 110, 155, 0.1), rgba(35, 110, 155, 0.5))`,
  ].reverse()

  return (
    <StyledBackgroundImage
      Tag={`section`}
      id={`test`}
      fluid={backgroundFluidImageStack}
    >
      <Button
        href={
          "https://wa.me/573183472689?text=Bienvenidos%20a%20la%20%23FamiliaFRC%2C%20para%20mas%20informacion%20sobre%20entrenamientos%20y%20partidos%2C%20contactanos%20en%20breve%20te%20responderemos."
        }
      >
        Únete a la familia Fénix
        <FontAwesomeIcon icon={"arrow-alt-circle-right"} />
      </Button>
    </StyledBackgroundImage>
  )
}

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 600px;
  box-shadow: ${Shadows.level2};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.utility.white.default};
  @media screen and (max-width: 900px) {
    height: 400px;
  }
`
const Button = styled("a")`
  background-color: ${Colors.primary.blue.darker};
  box-shadow: ${Shadows.level2};
  border-radius: 29px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  padding: ${Spacing.md} ${Spacing.xl};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 400px;
  font-size: ${Typography.subTitle.fontSize};
  &:hover {
    transform: translateY(-1px);
    box-shadow: ${Shadows.level4};
    background-color: ${Colors.primary.blue.default};
  }
  @media screen and (max-width: 900px) {
    margin-top: 270px;
    padding: ${Spacing.md} ${Spacing.xl};
  }
  @media screen and (max-width: 600px) {
    margin-top: 270px;
    font-size: ${Typography.body.fontSize};
    padding: ${Spacing.md} ${Spacing.lg};
  }
  svg {
    margin-left: ${Spacing.md};
  }
`

export default Welcome
